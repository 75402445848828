import { useContext } from 'react';
import { Context } from '../Context';
import { isTrackingDisabled, debug } from './debugging';
import { AppContent } from '../types';

export interface Tracking {
  sendReadyEvent(): void;
  registerImpressionTracking(): void;
}

export function useTracking(
  featureAppProps: AppContent,
  ref: React.MutableRefObject<null>,
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
): Tracking {
  if (isTrackingDisabled()) {
    debug('TRACKING DISABLED');

    return {
      sendReadyEvent: () => {
        debug('TRACKING not executed: sendReadyEvent');
      },
      registerImpressionTracking: () => {
        debug('TRACKING not executed: registerImpressionTracking');
      },
    };
  }

  // eslint-disable-next-line prefer-rest-params
  debug('Tracking initialized', arguments);

  const { trackingService } = useContext(Context);
  const featureAppName = (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendReadyEvent: () => {
      if (trackingService) {
        const pageSetUpData = {
          attributes: {
            articleFormat: featureAppProps.articleFormat,
            articleID: featureAppProps.articleID,
            articlePublicationDate: featureAppProps.articleCreationDate, // attention: different prop names
            articleReadingTime: featureAppProps?.articleReadingTime?.toString() || '',
          },
        };

        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: 'article page', // as this FA is primary consumer and the "feature_app_ready" actually is a "page_load" event, "article page" is the appropriate event name
            },
          },
          pageUpdate: pageSetUpData,
          componentUpdate: defaultComponentAdditions,
        };

        debug(
          'dataLayer.page before completing it',
          JSON.stringify(trackingService.getDatalayer().page),
        );

        // alternative approach
        // trackingService.updatePageInfo(pageSetUpData);

        debug(
          'dataLayer.page right before feature_app_ready event',
          JSON.stringify(trackingService.getDatalayer().page),
        );

        debug('Tracking: sendReadyEvent', readyEvent);
        trackingService.track(readyEvent);
      }
    },
    registerImpressionTracking: () => {
      if (trackingService && ref.current) {
        debug('Tracking: registerImpressionTracking');
        trackingService.registerImpressionTracking(ref.current, () => {
          return {
            event: {
              attributes: {
                componentName: componentNameWithDashes,
                elementName: '',
                label: '',
                clickID: '',
                pos: '',
                targetURL: '',
                value: '',
              },
              eventInfo: {
                eventAction: 'impression',
                eventName: `${componentNameWithSpaces} - impression`,
              },
            },
            componentUpdate: defaultComponentAdditions,
          };
        });
      }
    },
  };
}
