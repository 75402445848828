/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import { Text } from '@audi/audi-ui-react';
import {
  renderTextWithFootnotesReferencesV2,
  cleanupEmissionConsumptionMarkup,
  getConsumptionsAndEmissions,
} from '@oneaudi/feature-app-utils';
import { VueFormatterServiceInterfaceV1 } from '@oneaudi/vue-formatter-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { debug } from '../../utils/debugging';

export interface ConsumptionAndEmissionsProps {
  caeIds?: string[];
  onCaeReady?: () => void;
  vueFormatterService: VueFormatterServiceInterfaceV1;
  localeService: LocaleServiceV1;
}

interface CaeData {
  consumption: string | React.ReactNode;
  emissions: string | React.ReactNode;
  emissionClass: string | React.ReactNode;
  dischargedCO2Class?: string | React.ReactNode;
  dischargedConsumption?: string | React.ReactNode;
}

export const ConsumptionAndEmissions: React.FC<ConsumptionAndEmissionsProps> = ({
  caeIds,
  onCaeReady,
  vueFormatterService,
  localeService,
}) => {
  if (!caeIds || !caeIds.length) return null;

  const [caeData, setCaeData] = React.useState<CaeData[]>();

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      const consumptionsAndEmissionsValues = await getConsumptionsAndEmissions(
        caeIds,
        vueFormatterService,
        localeService,
      );

      if (caeIds && caeIds.length) {
        debug(
          `CaE loaded: [${caeIds.join(',')}] / ${JSON.stringify(consumptionsAndEmissionsValues)}`,
        );
      }

      const fetchedCaeData: CaeData[] = consumptionsAndEmissionsValues
        .filter((cae) => {
          return (
            cae?.formattedConsumption ||
            cae?.formattedEmission ||
            cae?.formattedCo2Class ||
            cae?.formattedDischargedConsumption ||
            cae?.formattedDischargedCo2Class
          );
        })
        .map((cae) => {
          return {
            consumption: cae.formattedConsumption
              ? renderTextWithFootnotesReferencesV2(
                  cleanupEmissionConsumptionMarkup(cae.formattedConsumption),
                )
              : '',
            emissions: cae.formattedEmission
              ? renderTextWithFootnotesReferencesV2(
                  cleanupEmissionConsumptionMarkup(cae.formattedEmission),
                )
              : '',
            emissionClass: cae.formattedCo2Class
              ? renderTextWithFootnotesReferencesV2(
                  cleanupEmissionConsumptionMarkup(cae.formattedCo2Class),
                )
              : '',
            dischargedConsumption: cae.formattedDischargedConsumption
              ? renderTextWithFootnotesReferencesV2(
                  cleanupEmissionConsumptionMarkup(cae.formattedDischargedConsumption),
                )
              : '',
            dischargedCO2Class: cae.formattedDischargedCo2Class
              ? renderTextWithFootnotesReferencesV2(
                  cleanupEmissionConsumptionMarkup(cae.formattedDischargedCo2Class),
                )
              : '',
          };
        });

      if (fetchedCaeData.length) setCaeData(fetchedCaeData);
    })();
  }, []);

  React.useEffect(() => {
    if (caeData?.length) onCaeReady?.();
  }, [caeData?.length]);

  if (!caeData?.length) return null;

  return (
    <>
      {caeData.map((x, i) => (
        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        <Text key={['a', i, x.emissionClass].join('_')} variant="copy2" spaceStackStart="xs">
          {x.consumption}
          {x.emissions && <span>; {x.emissions}</span>}
          {x.emissionClass && <span>; {x.emissionClass}</span>}
          {x.dischargedConsumption && <span>; {x.dischargedConsumption}</span>}
          {x.dischargedCO2Class && <span>; {x.dischargedCO2Class}</span>}
        </Text>
      ))}
    </>
  );
};

ConsumptionAndEmissions.displayName = 'ConsumptionAndEmissions';
